import {Order} from "../../models/order";

// Action Types
export const PUSH_ORDERS = 'PUSH_ORDERS';
export const UPDATE_ORDER = 'UPDATE_ORDER';

interface PushOrders {
    type: typeof PUSH_ORDERS,
    payload: {
        orders: Order[]
    }
}

interface UpdateOrder {
    type: typeof UPDATE_ORDER,
    payload: {
        order: Order
    }
}

export type OrderActionTypes = PushOrders | UpdateOrder;


export interface OrdersState {
    loading: boolean,
    orders: Order[]
}