import React, {useEffect, useRef, useState} from "react";
import Heading from "../components/heading";
import FormCheckBox from "../../../components/forms/form-checkbox";
import {Order} from "../../../models/order";
import {orders} from "../../../utils/firebase.utils";
import OrderRows from "./components/order-row";
import {limit, onSnapshot, orderBy, query} from "firebase/firestore";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import {Dispatch} from "redux";
import {OrderActionTypes, PUSH_ORDERS} from "../../../redux/types/order-types";

const Orders = () => {

    const ref = useRef<HTMLDivElement | null>(null);
    const [selected, setSelected] = useState<string[]>([]);

    const ordersState = useSelector((state: RootState) => state.orders);
    const dispatch = useDispatch<Dispatch<OrderActionTypes>>();

    useEffect(() => {

        let ref = query(
            orders,
            orderBy('created', 'desc'),
            limit(20)
        );

        let observer = onSnapshot(ref, (snapshot) => {
            if (!snapshot.empty) {
                let t: Order[] = [];
                snapshot.docs.forEach((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    t.push(new Order(data));
                })
                dispatch({type: PUSH_ORDERS, payload: { orders: t }});
            }
        })

        return () => observer();
    }, [dispatch])

    const selectOrder = (id: string) => {
        let t: string[] = [...selected];

        if (t.includes(id)) {
            t.splice(t.indexOf(id), 1);
        } else {
            t.push(id);
        }
        setSelected(t);
    }

    return (
        <>
            <div className="sm:pt-7 pt-4 flex flex-col w-full bg-white sticky top-16 z-40" ref={ref}>
                <Heading title={`Orders`}/>
                <div className="flex items-center space-x-3 sm:mt-7 mt-4">
                    <div className="border-b-2 border-indigo-500 text-primary-default pb-1.5 font-medium">All</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5">Unfulfilled</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5 sm:block hidden">Open</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5 sm:block hidden">Closed</div>
                </div>
                <div className={"h-px w-full"} style={{ background: "rgb(229, 231, 235)" }}/>
            </div>
            <div className="space-y-6">
                <table className="w-full text-left">
                    <thead className={`h-12 bg-white px-3 py-3 text-gray-400 sticky relative z-40`} style={{ top: `${ref.current ? `${(ref.current?.clientHeight ?? 0) + 64}px` : ""}` }}>
                        <tr>
                            <th className="px-2 sm:px-3 md:table-cell hidden">
                                <FormCheckBox onChange={() => { if (selected.length === ordersState.orders.length) setSelected([]); else setSelected(ordersState.orders.map(o => o.id)); }} checked={ordersState.orders.length > 0 && selected.length === ordersState.orders.length}/>
                            </th>
                            <th className="font-normal sm:p-3 py-2 px-1">Order</th>
                            <th className="font-normal sm:p-3 py-2 px-1 md:table-cell hidden">Date</th>
                            <th className="font-normal sm:p-3 py-2 px-1">Items</th>
                            <th className="font-normal sm:p-3 py-2 px-1 md:table-cell hidden">Ship To</th>
                            <th className="font-normal sm:p-3 py-2 px-1 md:table-cell hidden">Total</th>
                            <th className="font-normal sm:p-3 py-2 px-1 text-gray-400">Fulfillment</th>
                        </tr>
                        <span className={"h-px w-full absolute"} style={{ background: "rgb(229, 231, 235)" }}/>
                    </thead>
                    <tbody className="text-raisinBlack relative">
                        <OnLoadViewer component={"tr"} loading={ordersState.loading} className={"absolute"}>
                            {
                                ordersState.orders.map((order) => {
                                    return <OrderRows key={order.orderId} onSelect={selectOrder} selected={selected} order={order}/>
                                })
                            }
                        </OnLoadViewer>
                    </tbody>
                </table>
            </div>
        </>
)
}

export default Orders;