import {OrderActionTypes, OrdersState, PUSH_ORDERS} from "../types/order-types";
import {Order} from "../../models/order";

const initialState: OrdersState = {
    loading: true,
    orders: []
};

export function orderReducer (state = initialState, action: OrderActionTypes): OrdersState {
    switch (action.type) {
        case PUSH_ORDERS:

            let initialOrderValue = [...state.orders];
            let newOrders: Order[] = [...action.payload.orders];

            initialOrderValue.forEach((o) => {
                if (newOrders.findIndex((ord) => ord.id === o.id) === -1)
                    newOrders.push(o);
            })

            newOrders.sort((a,b) => b.created.getTime() - a.created.getTime())

            return {
                ...state,
                loading: false,
                orders: newOrders
            };
        case "UPDATE_ORDER":
            let initialOrderValueX = [...state.orders];
            let toUpdateIndex = initialOrderValueX.findIndex((o) => o.id === action.payload.order.id);

            if (toUpdateIndex !== -1) {
                initialOrderValueX[toUpdateIndex] = action.payload.order;
            }

            return {
                ...state,
                loading: false,
                orders: initialOrderValueX
            };
        default:
            return state
    }
}

export default orderReducer;