import Card from "../../../../components/cards/card";
import React from "react";
import {Order} from "../../../../models/order";
import {
    AiFillCaretDown,
    BsArrowReturnLeft,
    FiCircle,
    FiX,
} from "react-icons/all";
import {Popover} from "@headlessui/react";
import {formatCurrency} from "../../../../utils/others";
const OrderItems = ({order}: {order?: Order}) => {

    return (
        <Card className={"overflow-visible space-y-4"}>
            <div className={"text-xl font-semibold max-w-sm flex items-center"}>
                {["processing", "manifested", "failed"].includes(order?.status ?? "") &&
                <div className={"bg-yellow-100 p-1 rounded-full mr-2"}>
                    <FiCircle className={"text-yellow-800"} size={18} strokeDasharray={6} strokeWidth={4}/>
                </div>
                }

                {["processing", "manifested", "failed"].includes(order?.status ?? "") && "Unfulfilled"}
                {/*____*/}
                {["cancelled"].includes(order?.status ?? "") &&
                <div className={"bg-red-100 p-1 rounded-full mr-2"}>
                    <FiX className={"text-red-800"} size={18} strokeWidth={3}/>
                </div>
                }

                {["cancelled"].includes(order?.status ?? "") && "Cancelled"}
                {/*____*/}
                {["returned"].includes(order?.status ?? "") &&
                <div className={"bg-red-100 p-1 rounded-full mr-2"}>
                    <BsArrowReturnLeft className={"text-red-800"} style={{ transform: "scaleX(-1) rotate(180deg)" }} size={18} strokeWidth={1}/>
                </div>
                }

                {["returned"].includes(order?.status ?? "") && "Returned"}
                {/*____*/}
                {["in-transit", "delivered"].includes(order?.status ?? "") && "Fulfilled"}
                {/*____*/}
                {["returned-by-customer"].includes(order?.status ?? "") && "Returned by Customer"}
            </div>
            <div>
                {order?.waybill &&
                <div>
                    Delhivery
                    <div className={"flex items-center"}>
                        <a target={"_blank"} rel="noreferrer" href={`https://www.delhivery.com/track/package/` + order?.waybill} className={"text-primary-default underline text-sm mr-2"}>
                            {order?.waybill}
                        </a>
                        {
                            (() => {
                                switch (order?.status) {
                                    case "processing":
                                        return <div className={"flex items-center px-2 py-px bg-yellow-50 border border-1 border-yellow-100 text-yellow-800 rounded-full font-medium"}>
                                            <FiCircle className={"mr-2 text-yellow-800"} size={8} strokeWidth={5}/> Unfulfilled
                                        </div>
                                    case "cancelled":
                                        return <div className={"px-2 py-px bg-red-100 text-red-800 rounded-full font-medium"}>
                                            Cancelled
                                        </div>
                                    case "manifested":
                                        return <div className={"flex items-center px-2 py-px bg-yellow-50 border border-1 border-yellow-100 text-yellow-800 rounded-full font-medium"}>
                                            <FiCircle className={"mr-2 text-yellow-800 bg-yellow-800 rounded-full"} size={8} strokeWidth={5}/> Manifested
                                        </div>
                                    case "in-transit":
                                        return <div className={"px-2 py-px bg-gray-200 text-gray-800 rounded-full font-medium"}>
                                            Shipped
                                        </div>
                                    case "delivered":
                                    case "returned-by-customer":
                                        return <div className={"px-2 py-px bg-green-100 text-green-800 rounded-full font-medium"}>
                                            Delivered
                                        </div>
                                    case "returned":
                                        return <div className={"px-2 py-px bg-red-100 text-red-800 rounded-full font-medium"}>
                                            Returned
                                        </div>
                                    case "failed":
                                        return <Popover className="relative h-full w-full">
                                            <Popover.Button className={"w-full select-none text-left outline-none focus:outline-none ring-0"}>
                                                <div className="flex items-center">
                                                    <div className={"px-2 py-px bg-red-100 text-red-800 rounded-full font-medium"}>
                                                        Failed
                                                    </div>
                                                    <AiFillCaretDown size={10} className={"ml-2 text-grey-400"}/>
                                                </div>
                                            </Popover.Button>
                                            <Popover.Panel className="absolute z-50 w-screen max-w-xs mt-1 transform left-0">
                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white cursor-default">
                                                    <div className="flex items-center p-4">
                                                        <div>
                                                            {order.failed_reason.map((text) => {
                                                                return <p>{text}</p>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Popover>
                                    default:
                                        return <div/>
                                }
                            })()
                        }
                    </div>
                </div>
                }
                {order?.return_reason.waybill && order?.status === "returned-by-customer" &&
                <div className={"mt-2"}>
                    Delhivery (Return)
                    <div className={"flex items-center"}>
                        <a target={"_blank"} rel="noreferrer" href={`https://www.delhivery.com/track/package/` + order?.waybill} className={"text-primary-default underline text-sm mr-2"}>
                            {order?.return_reason.waybill}
                        </a>
                        {
                            (() => {
                                switch (order?.return_reason.status) {
                                    case "scheduled":
                                        return <div className={"flex items-center px-2 py-px bg-yellow-50 border border-1 border-yellow-100 text-yellow-800 rounded-full font-medium"}>
                                            <FiCircle className={"mr-2 text-yellow-800 bg-yellow-800 rounded-full"} size={8} strokeWidth={5}/> Scheduled
                                        </div>
                                    case "in-transit":
                                        return <div className={"px-2 py-px bg-gray-200 text-gray-800 rounded-full font-medium"}>
                                            En-Route
                                        </div>
                                    case "returned":
                                        return <div className={"px-2 py-px bg-green-100 text-green-800 rounded-full font-medium"}>
                                            Delivered
                                        </div>
                                    case "failed":
                                        return <Popover className="relative h-full w-full">
                                            <Popover.Button className={"w-full select-none text-left outline-none focus:outline-none ring-0"}>
                                                <div className="flex items-center">
                                                    <div className={"px-2 py-px bg-red-100 text-red-800 rounded-full font-medium"}>
                                                        Failed
                                                    </div>
                                                    <AiFillCaretDown size={10} className={"ml-2 text-grey-400"}/>
                                                </div>
                                            </Popover.Button>
                                            <Popover.Panel className="absolute z-50 w-screen max-w-xs mt-1 transform left-0">
                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white cursor-default">
                                                    <div className="flex items-center p-4">
                                                        <div>
                                                            {order.failed_reason.map((text) => {
                                                                return <p>{text}</p>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Popover>
                                    default:
                                        return <div/>
                                }
                            })()
                        }
                    </div>
                </div>
                }
            </div>
            {order?.waybill &&
            <div className={"h-px w-full bg-gray-200"}/>
            }
            <div className={"divide-y w-full"}>
                Order Items ({order?.items.length})
                {
                    order?.items.map((item) => {
                        return <div className={"grid grid-cols-5 py-3"} key={item.id}>
                            <div className={"col-span-3 flex"}>
                                <div className={"w-10 h-10 rounded-md mr-4"} style={{ background: `#f6f4f5 url(${item.thumbnail}) center center / cover` }}/>
                                <div>
                                    <p className={"font-medium text-sm"}>
                                        {item.title}
                                    </p>
                                    <div className="text-gray-400 sm">
                                        {item.selectionVariation}
                                    </div>
                                </div>
                            </div>
                            <div className={"text-raisinBlack text-sm ml-auto px-6"}>
                                {formatCurrency(item.price)} × {item.quantity}
                            </div>
                            <div className={"text-sm ml-auto text-raisinBlack pl-6"}>
                                {formatCurrency(item.totalPrice)}
                            </div>
                        </div>
                    })
                }
            </div>
        </Card>
    )

}

export default OrderItems;