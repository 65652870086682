import Card from "../../../../components/cards/card";
import React from "react";
import {UserData} from "../../../../models/user-data";
import CopyToClipboard from "react-copy-to-clipboard"
import Address from "../../../../models/address";

const CustomerInfo = ({customerData, address}: {customerData: UserData, address: Address}) => {

    return (
        <Card className={"bg-gray-50 overflow-visible space-y-4 px-0"}>
            <div className={"text-lg font-semibold max-w-sm"}>
                Customer
            </div>
            <div className={"flex"}>
                <a href={"mailto:" + customerData?.email} className={"text-primary-default underline text-sm"}>
                    {customerData ? customerData?.email : "Not Provided"}
                </a>
                <div className={"ml-auto"}>
                    <CopyToClipboard text={`${customerData?.email}`}>
                        <p className={"uppercase tracking-widest flex-shrink-0 text-xs font-medium text-primary-default cursor-pointer"} onClick={() => {  }}>
                            COPY
                        </p>
                    </CopyToClipboard>
                </div>
            </div>
            <div className={"h-px w-full bg-gray-200"}/>
            <div className={"text-sm font-medium max-w-sm"}>
                Membership
            </div>
            <div className={"flex"}>
                <div>
                    <p>
                        {(() => {
                            if (customerData && customerData.points <= 1000)
                                return <span>Basic</span>
                            else if (customerData.points > 1000 && customerData.points <= 10000)
                                return <span>Gold</span>
                            else
                                return <span>Platinum</span>
                        })()}
                        {" Membership"}
                    </p>
                    <p>
                        {customerData.redeemablePoints} Points
                    </p>
                </div>
            </div>
            <div className={"h-px w-full bg-gray-200"}/>
            <div className={"text-sm font-medium max-w-sm"}>
                Default Address
            </div>
            <div className={"flex"}>
                <div>
                    <p className={"mb-2"}>
                        {address.name}
                    </p>
                    <p>
                        {address.primaryLine}
                    </p>
                    <p className={"mb-1"}>
                        {address.secondaryLineWithZipCode}
                    </p>
                    <p>
                        +91 {address.phoneNumber}
                    </p>
                </div>
                <div className={"ml-auto"}>
                    <CopyToClipboard text={`${address.name}, ${address.primaryLine}, ${address.secondaryLineWithZipCode}, +91 ${address.phoneNumber}`}>
                        <p className={"uppercase tracking-widest flex-shrink-0 text-xs font-medium text-primary-default cursor-pointer"} onClick={() => {  }}>
                            COPY
                        </p>
                    </CopyToClipboard>
                </div>
            </div>

        </Card>
    )

}

export default CustomerInfo;