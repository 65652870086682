import { combineReducers } from 'redux';
import userDataReducer from "./reducers/user-data-reducer";
import siteReducer from "./reducers/site-reducer";
import orderReducer from "./reducers/order-reducer";

const allReducers = combineReducers({
    userData: userDataReducer,
    site: siteReducer,
    orders: orderReducer
});

export type RootState = ReturnType<typeof allReducers>
export default allReducers;