import React from "react";
import FormCheckBox from "../../../../components/forms/form-checkbox";
import {FiCircle} from "react-icons/all";
import {ProductItem} from "../../../../models/product-item";
import {useHistory} from "react-router-dom";

const ProductRow = ({product, selected, onSelect}: { product: ProductItem, selected: string[], onSelect: (id:string) => void }) => {

    const history = useHistory();

    return (
        <tr className={" border-b border-gray-200 hover:bg-gray-50 cursor-pointer"} onClick={() => { history.push("/products/" + product.id); }}>
            <td className={"px-3"} onClick={(event) => { event.stopPropagation(); onSelect(product.id) }}>
                <FormCheckBox  checked={selected.includes(product.id)} onChange={() => {}}/>
            </td>
            <td className="sm:p-3 py-2 px-1">
                <div className="h-16 w-16 rounded-md bg-gray-50 overflow-hidden">
                    <img src={product.thumbnail_src} alt={product.title} className={"object-cover"}/>
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1">
                {product.title}
            </td>
            <td className="sm:p-3 py-2 px-1 md:table-cell hidden">
                <div className="flex items-center">
                    <div className="flex flex-col">
                        {product.categoryTitle}
                        {/*<div className="text-gray-400 text-xs">*/}
                        {/*    {order.shipping.city}*/}
                        {/*</div>*/}
                    </div>
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1" >
                <div className="flex items-center">
                    {
                        product.disabled ?
                            <div className={"px-3 py-1 bg-red-100 text-red-800 rounded-full font-medium"}>
                                Disabled
                            </div>
                            :
                            (product.out_of_stock ?
                                    <div className={"flex items-center px-3 py-1 bg-gray-50 border border-1 border-gray-100 text-gray-800 rounded-full font-medium"}>
                                        <FiCircle className={"mr-2 text-gray-800"} size={8} strokeWidth={5}/> Out of Stock
                                    </div>
                                    :
                                    <div className={"px-3 py-1 bg-green-100 text-green-800 rounded-full font-medium"}>
                                        Active
                                    </div>
                            )
                    }
                </div>
            </td>
        </tr>
    )

}

export default ProductRow;