export class SiteSettings {
    id: string;

    site_short_name: string;
    site_full_name: string;
    subtitle: string;

    logo: string;
    icon: string;

    background_auth: string;
    background_theme: string;

    location: string;
    website: string;


    constructor(data?: { [field: string]: any }) {

        this.id = data?.id ?? '';

        this.site_short_name = data?.site_short_name ?? '';
        this.site_full_name = data?.site_full_name ?? '';
        this.subtitle = data?.subtitle ?? '';

        this.logo = data?.logo ?? '';
        this.icon = data?.icon ?? '';

        this.background_auth = data?.background_auth ?? '';
        this.background_theme = data?.background_theme ?? '';

        this.location = data?.location ?? '';
        this.website = data?.website ?? '';
    }
}