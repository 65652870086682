import React, {useState} from "react";
import FormInput from "../../components/forms/form-input";
import PrimaryButton from "../../components/buttons/button";
import FormWrapper from "../../components/forms/form-wrapper";
import {auth} from "../../utils/firebase.utils";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import { sendPasswordResetEmail } from "firebase/auth";
import {FiCheck} from "react-icons/fi"
import FormErrorText from "../../components/forms/form-error-text";

const ForgotPassword = () => {

    const [success, setSuccess] = useState(false);
    const site = useSelector((state: RootState) => state.site);
    const { loading } = useSelector((state: RootState) => state.userData);

    // Handle Forgot Password
    const sendEmail = (values: Record<string, string | boolean>) => {
        return new Promise((resolve, reject) => {
            if (!success) {
                sendPasswordResetEmail(auth, values.email.toString())
                    .then(() => {
                        resolve("Success");
                        setSuccess(true);
                    })
                    .catch(async (err) => {
                        console.log(err.code);
                        switch (err.code) {
                            case "auth/user-not-found":
                                return reject({email: "Couldn't find your account."});
                            case "auth/invalid-email":
                                return reject({email: "Please enter a valid Email Address."});
                            default:
                                return reject("Something went wrong on our side.");
                        }
                    });
            } else {
                resolve("");
            }
        });
    };

    return (
        <div className="min-h-screen flex justify-center bg-white items-start sm:items-center">
            <div className="max-w-md w-full m-6 mt-10 sm:m-20">
                <Transition
                    appear={true}
                    show={!loading}
                    enter="transition ease-in-out delay-150 duration-300 transform"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100  translate-y-0"
                    className="max-w-md w-full space-y-8"
                >
                    <div>
                        <h2 className="text-3xl font-bold text-gray-900">Reset your password</h2>
                        <p className="text-sm text-gray-700 mt-2">
                            Enter your email and we'll send you instructions on how to reset your password.
                        </p>
                    </div>
                    <FormWrapper onSubmit={sendEmail} className={"space-y-4"} onValueChange={() => { if (success) { setSuccess(false) } }}>
                        <div>
                            <FormInput
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                placeholder="Email address"
                                label="Email address"
                                hideLabel
                                required
                            />
                            <FormErrorText/>
                        </div>
                        <div>
                            <PrimaryButton type={"submit"} icon={success ? FiCheck : undefined} className={`${success ? "bg-green-600 pointer-events-none" : ""}`}>
                                {success ? "Sent" : "Send Instructions"}
                            </PrimaryButton>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <span>
                                    Go back to {" "}
                                </span>
                                <Link to="/sign-in" className="font-medium text-primary-default hover:text-primary-dark">
                                    Sign In
                                </Link>
                            </div>
                        </div>
                    </FormWrapper>
                </Transition>
            </div>
            <div className={"w-full min-h-screen bg-gray-100 hidden relative sm:block"}>
                <Transition
                    show={!site.loading}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("${site.background_auth}") left top / cover` }}/>
                </Transition>
            </div>
        </div>
    )
}

export default ForgotPassword;