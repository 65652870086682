import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyABQ3Z0s62a3Z0FHBZwolbQDSAHo5-ql4M",
    authDomain: "groomd-mens.firebaseapp.com",
    databaseURL: "https://groomd-mens.firebaseio.com",
    projectId: "groomd-mens",
    storageBucket: "groomd-mens.appspot.com",
    messagingSenderId: "222946718821",
    appId: "1:222946718821:web:e7b5326f77645ae7af028c",
    measurementId: "G-04EB94LK8R"
};

const firebaseApp = initializeApp(firebaseConfig);


// Firebase Instances
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

// Collections
export const users = collection(firestore, "users");
export const orders = collection(firestore, "orders");
export const products = collection(firestore, "products");
export const data_index = collection(firestore, "data_index");
export const sites = collection(firestore, "sites");

export const BASE_API = "https://us-central1-groomd-mens.cloudfunctions.net"

