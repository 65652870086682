import {CartItem} from "./product-item";

export class BirthDate {
    day: number | null = null;
    month: number | null = null;
    year: number | null = null;

    get isValid () {
        return this.day!==null && this.month!==null;
    }

    get date() {
        if (this.day!==null && this.month!==null && this.year!==null)
            return new Date(`${this.month.toString()}-${this.day.toString()}-${this.year.toString()}`);
        else
            return null;
    }

    get formattedDate() {
        if (this.day!==null && this.month!==null && this.year!==null)
            // return moment(this.date).format('MMM DD, YYYY')
            return "";
        else
            return "";
    }
    // TODO datwes formating
    get inputDate() {
        if (this.day!==null && this.month!==null && this.year!==null)
            // return moment(this.date).format('YYYY-MM-DD')
            return "";
        else
            return "";
    }

    constructor(data?: { [field: string]: any }) {
        this.day = data?.day ?? null;
        this.month = data?.month ?? null;
        this.year = data?.year ?? null;
    }

    toJson(): BirthDate {
        return Object.assign({}, this);
    }
}

export class UserData{
    uid: string = "";
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    birthDate: BirthDate | null = null;
    points: number = 0;
    redeemablePoints?: number = 0;
    orders?: number = 0;
    seenPopups: string[] = [];
    cart: CartItem[] = [];
    productsBought: string[] = [];
    productsReviewed: string[] = [];
    customerId: string = "";
    orderTotal: number;
    delivered_orders: number;
    recent_city: string;
    recent_state: string;

    get name() {
        if (this.firstName!==null && this.lastName!==null)
            return this.firstName + " " + this.lastName;
        else
            return "";
    }

    constructor(data?: { [field: string]: any }) {
        this.uid = data?.uid ?? "";
        this.firstName = data?.firstName ?? "";
        this.lastName = data?.lastName ?? "";
        this.email = data?.email ?? "";
        this.birthDate = data?.birthDate != null ? new BirthDate(data.birthDate) : null;
        this.points = data?.points ?? 0;
        this.redeemablePoints = data?.redeemablePoints ?? 0;
        this.orders = data?.orders ?? 0;
        this.orderTotal = data?.orderTotal ?? 0;
        this.delivered_orders = data?.delivered_orders ?? 0;
        this.recent_city = data?.recent_city ?? "";
        this.recent_state = data?.recent_state ?? "";
        this.productsBought = data?.productsBought ?? [];
        this.productsReviewed = data?.productsReviewed ?? [];
        this.seenPopups = data?.seenPopups ?? [];
        this.customerId = data?.customerId ?? "";
        if (data?.cart!=null) {
            this.cart = [];
            data.cart.forEach((c: any) => {
                this.cart.push(new CartItem(c));
            });
        } else {
            this.cart=[];
        }
    }

    toJson(): UserData {
        return Object.assign({}, this);
    }
}