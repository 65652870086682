import React, {useEffect, useState} from "react";
import {list, ref, StorageReference} from "firebase/storage";
import {storage} from "../../utils/firebase.utils";
// @ts-ignore
import { Folder, Image, Video, Paper } from 'react-iconly'

const FileSelector = ({onSelect, allowed}: {onSelect:(selectedPath:string) => void, allowed:string[]}) => {

    const [path, setPath] = useState("/");
    const [loading, setLoading] = useState(true);

    const [prefixes, setPrefixes] = useState<StorageReference[]>([]);
    const [items, setItems] = useState<StorageReference[]>([]);

    useEffect(() => {
        const listRef = ref(storage, path);
        setLoading(true);
        list(listRef, { maxResults: 100 }).then((page) => {
            setPrefixes(page.prefixes);
            setItems(page.items);
            setLoading(false);
        });
    }, [path])

    const isImage = (path:string) => path.toLowerCase().search(new RegExp(/\.(jpe?g|png|webp|bmp)$/i)) !== -1
    const isVideo = (path:string) => path.toLowerCase().search(new RegExp(/\.(mp4|webm)$/i)) !== -1

    const checkAllowed = (path:string) => {
        let allow = false;
        if (allowed.includes("images")) {
            allow = isImage(path)
        }
        if (allowed.includes("video")) {
            allow = isVideo(path)
        }

        return allow;
    }

    return (
        <div>
            <div className={"flex py-2 mb-4 "}>
                <p className={"font-normal font-sans text-gray-400 hover:text-gray-700 cursor-pointer"} onClick={() => { setPath("/") }}>
                    Files
                </p>
                {path.split("/").map((p, index) => {
                    if (index !== 0) {
                        return <div className={"flex"}>
                            <p className={"mx-2 font-normal font-sans text-gray-400 hover:text-gray-700 cursor-pointer"} onClick={() => { setPath(p) }}>
                                /
                            </p>
                            <p className={"font-normal font-sans text-gray-400 hover:text-gray-700 cursor-pointer"}
                               onClick={() => {
                                   let currentPath = "";
                                   path.split("/").forEach((xP, xIndex) => {
                                       if (xIndex!==0 && xIndex <= index) {
                                           currentPath = `${currentPath}/${xP}`
                                       }
                                   })
                                   setPath(currentPath)
                               }}
                            >
                                {p}
                            </p>
                        </div>
                    } else {
                        return <div/>
                    }
                })}
            </div>
            <div className={`grid grid-cols-3 gap-4 transition duration-150 ${loading ? "opacity-0" : "opacity-100"}`}>
                {prefixes.map((prefix) => {
                    return <button
                        onDoubleClick={() => { setPath("/"+prefix.name) }}
                        className={"flex items-center px-4 py-2 rounded-lg border border-gray-200 font-sans font-normal text-sm hover:bg-gray-50 focus:bg-gray-50 hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-light"}
                    >
                        <Folder set="bulk" size={22} className={"text-primary-light mr-3 flex-shrink-0"}/>
                        <span className={"truncate overflow-ellipsis"}>
                            {prefix.name}
                        </span>
                    </button>
                })}
                {items.map((item) => {

                    let color =  checkAllowed(item.name)  ? "text-primary-light" : "text-gray-400"

                    return <button
                        onDoubleClick={() => {
                            if (checkAllowed(item.name))
                                onSelect("/"+item.fullPath);
                        }}
                        disabled={!checkAllowed(item.name)}
                        className={`${checkAllowed(item.name) ? "" : "pointer-events-none cursor-not-allowed"} flex items-center px-4 py-2 rounded-lg border border-gray-200 font-sans font-normal text-sm hover:bg-gray-50 focus:bg-gray-50 hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-light`}
                    >
                        {(() => {


                            if (isImage(item.name))
                                return <Image set="bulk" size={22} className={`${color} mr-3 flex-shrink-0`}/>
                            else if (isVideo(item.name))
                                return <Video set="bulk" size={22} className={`${color} mr-3 flex-shrink-0`}/>
                            else
                                return <Paper set="bulk" size={22} className={`${color} mr-3 flex-shrink-0`}/>
                        })()}

                        <span className={"truncate overflow-ellipsis"}>
                            {item.name}
                        </span>
                    </button>
                })}
            </div>
        </div>
    )

}

export default FileSelector;