import React  from "react";
import {Order} from "../../../../models/order";
import moment from "moment";
import FormCheckBox from "../../../../components/forms/form-checkbox";
import {AiFillCaretDown, FiCircle} from "react-icons/all";
import {Link} from "react-router-dom";
import { Popover } from "@headlessui/react";

const OrderRows = ({order, selected, onSelect}: { order: Order, selected: string[], onSelect: (id:string) => void }) => {

    return (
        <tr className={"border-b border-gray-200 hover:bg-gray-50 cursor-pointer"}>
            <td className={"md:table-cell hidden px-2 sm:px-3"} onClick={(event) => { event.stopPropagation(); onSelect(order.id) }}>
                <FormCheckBox onChange={() => {}}  checked={selected.includes(order.id)}/>
            </td>
            <td className="hidden sm:block ">
                <Link to={"/orders/" + order.id} className="flex items-center hover:text-primary-default sm:p-3 py-2 px-1">
                    <div>
                        <span className={"font-medium"}>
                            #{order.orderId}
                        </span>
                        <div className="flex text-gray-400 text-xs items-center">
                            View Details
                        </div>
                    </div>
                </Link>
            </td>
            <td className="block sm:hidden">
                <Link to={"/orders/" + order.id} className="sm:p-3 py-2 px-1">
                    <div className="items-center">
                       <span className={"font-medium"}>
                            #{order.orderId}
                        </span>
                    </div>
                    <div className="text-xs items-center text-gray-400">
                        {moment(order.created).format("DD.MM.YYYY")} at {moment(order.created).format("hh:mm A")}
                    </div>
                    <div className="text-gray-400 text-xs">
                        {order.shipping.name}
                    </div>
                </Link>
            </td>
            <td className="md:table-cell hidden">
                <Link to={"/orders/" + order.id} className="flex items-center sm:p-3 py-2 px-1">
                    <div className="flex items-center">
                        <div className="flex flex-col">
                            {moment(order.created).format("DD.MM.YYYY")}
                            <div className="text-gray-400 text-xs">
                                {moment(order.created).format("hh:mm A")}
                            </div>
                        </div>
                    </div>
                </Link>
            </td>
            <td className="hover:bg-gray-100" onClick={(event) => { event.stopPropagation(); }}>
                <Popover className="sm:relative h-full w-full">
                    <Popover.Button className={"sm:p-3 py-2 px-1 w-full select-none text-left outline-none focus:outline-none ring-0"}>
                        <div className="flex items-center justify-center sm:justify-start">
                            <div>
                                <div className="hidden sm:flex flex items-center">
                                    {order.items.length} {order.items.length === 1 ? "Item" : "Items"}  <AiFillCaretDown size={10} className={"ml-2 text-grey-400"}/>
                                </div>
                                <div className="visible sm:hidden flex items-center justify-center w-8 h-6 bg-gray-100 rounded-full font-medium">
                                    {order.items.length}
                                </div>

                                <div className="hidden sm:flex text-transparent text-xs">
                                    -
                                </div>
                            </div>
                        </div>
                    </Popover.Button>
                    {/*<Popover.Overlay className={"block sm:hidden fixed w-screen h-screen z-20 inset-0 bg-gray-500 bg-opacity-20 transition-opacity"}/>*/}
                    <Popover.Panel className="absolute z-30 w-full sm:w-screen sm:max-w-xs -mt-3 transform left-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white cursor-default">
                            <div className="flex items-center py-3 px-4">
                                <div className={"divide-y w-full"}>
                                    {
                                        order.items.map((item) => {
                                            return <div className={"flex items-start py-2"} key={item.id}>
                                                <div>
                                                    <p className={"font-medium"}>
                                                        {item.title}
                                                    </p>
                                                    <div className="text-gray-400 text-xs">
                                                        {item.selectionVariation}
                                                    </div>
                                                </div>
                                                <div className={"rounded-lg bg-gray-100 py-1 px-4 text-xs ml-auto"}>
                                                    {item.quantity}
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Popover>
            </td>
            <td className="sm:p-3 py-2 px-1 hover:bg-gray-100 md:table-cell hidden" onClick={(event) => { event.stopPropagation(); }}>
                <Popover className="relative h-full w-full">
                    <Popover.Button className={"w-full select-none text-left outline-none focus:outline-none ring-0"}>
                        <div className="flex items-center">
                            <div>
                                <div className={"flex items-center"}>
                                    {order.shipping.name}<AiFillCaretDown size={10} className={"ml-2 text-grey-400"}/>
                                </div>
                                <div className="text-gray-400 text-xs">
                                    {order.shipping.city}
                                </div>
                            </div>
                        </div>
                    </Popover.Button>
                    <Popover.Panel className="absolute z-50 w-screen max-w-xs mt-1 transform left-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white cursor-default">
                            <div className="flex items-center p-4">
                                <div>
                                    {order.shipping.name}
                                    <div className="text-gray-600 text-sm mt-2">
                                        {order.shipping.primaryLine}
                                    </div>
                                    <div className="text-gray-600 text-sm mb-2">
                                        {order.shipping.secondaryLineWithZipCode}
                                    </div>
                                    <a href={`tel:+91${order.shipping.phoneNumber}`} className="text-primary-default text-sm hover:opacity-60">
                                        +91 {order.shipping.phoneNumber}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Popover>
            </td>
            <td className="sm:p-3 py-2 px-1 md:table-cell hidden">
                <div className="flex items-center">
                    <div className="flex flex-col">
                        {Intl.NumberFormat('en-in', { style: "currency", currency: "inr", minimumFractionDigits: 2 }).format(order.total)}
                        <div className="text-gray-400 text-xs">
                            {order.paymentDetails}
                        </div>
                    </div>
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1" onClick={(event) => { if (order.status==="failed") event.stopPropagation(); }}>
                <div className="flex items-center">
                    {
                        (() => {
                            switch (order.status) {
                                case "processing":
                                    return <div className={"flex items-center px-3 py-1 bg-yellow-50 border border-1 border-yellow-100 text-yellow-800 rounded-full font-medium"}>
                                        <FiCircle className={"mr-2 text-yellow-800"} size={8} strokeWidth={5}/> Unfulfilled
                                    </div>
                                case "cancelled":
                                    return <div className={"px-3 py-1 bg-red-100 text-red-800 rounded-full font-medium"}>
                                        Cancelled
                                    </div>
                                case "manifested":
                                    return <div className={"flex items-center px-3 py-1 bg-yellow-50 border border-1 border-yellow-100 text-yellow-800 rounded-full font-medium"}>
                                        <FiCircle className={"mr-2 text-yellow-800 bg-yellow-800 rounded-full"} size={8} strokeWidth={5}/> Manifested
                                    </div>
                                case "in-transit":
                                    return <div className={"px-3 py-1 bg-gray-100 text-gray-800 rounded-full font-medium"}>
                                        Shipped
                                    </div>
                                case "delivered":
                                case "returned-by-customer":
                                    return <div className={"px-3 py-1 bg-green-100 text-green-800 rounded-full font-medium"}>
                                        Closed
                                    </div>
                                case "returned":
                                    return <div className={"px-3 py-1 bg-red-100 text-red-800 rounded-full font-medium"}>
                                        Returned
                                    </div>
                                case "failed":
                                    return <Popover className="relative h-full w-full">
                                        <Popover.Button className={"w-full select-none text-left outline-none focus:outline-none ring-0"}>
                                            <div className="flex items-center">
                                                <div className={"px-3 py-1 bg-red-100 text-red-800 rounded-full font-medium"}>
                                                    Failed
                                                </div>
                                                <AiFillCaretDown size={10} className={"ml-2 text-grey-400"}/>
                                            </div>
                                        </Popover.Button>
                                        <Popover.Panel className="absolute z-50 w-screen max-w-xs mt-1 transform left-0">
                                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white cursor-default">
                                                <div className="flex items-center p-4">
                                                    <div>
                                                        {order.failed_reason.map((text) => {
                                                            return <p>{text}</p>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Popover>
                                default:
                                    return <div/>
                            }
                        })()
                    }
                </div>
            </td>
        </tr>
    )

}

export default OrderRows;