import Card from "../../../../components/cards/card";
import React, {useEffect, useState} from "react";
import {Order} from "../../../../models/order";
import {UserData} from "../../../../models/user-data";
import {doc, getDoc} from "firebase/firestore";
import {users} from "../../../../utils/firebase.utils";
import CopyToClipboard from "react-copy-to-clipboard"

const CustomerDetails = ({order}: {order?: Order}) => {

    const [userData, setUserDate] = useState<UserData>()

    useEffect(() => {
        if (order?.uid) {
            getDoc(doc(users, order.uid)).then((doc) => {
                if (doc.exists()) {
                    let data = doc.data();
                    data.id = doc.id;
                    setUserDate(new UserData(data));
                }
            });
        }
    }, [order?.uid]);

    return (
        <Card className={"bg-gray-50 overflow-visible space-y-4 px-0"}>
            <div className={"text-lg font-semibold max-w-sm"}>
                Customer
            </div>
            <div>
                {
                    userData ?
                    <>
                        <a href={"/customers/" + userData?.uid} className={"text-primary-default underline text-sm"}>
                            {userData?.name}
                        </a>
                        <p className={"text-gray-600 text-sm"}>
                            {userData?.orders === 0 ? "No Orders" : `${userData?.orders} Order${userData?.orders === 1 ? "" : "s"}`}
                        </p>
                    </>
                    :
                    <p className={"text-gray-600 text-sm"}>
                        Guest Checkout
                    </p>
                }
            </div>
            <div className={"h-px w-full bg-gray-200"}/>
            <div className={"text-sm font-medium max-w-sm"}>
                Contact Information
            </div>
            <div className={"flex"}>
                <a href={"mailto:" + userData?.email} className={"text-primary-default underline text-sm"}>
                    {userData ? userData?.email : order?.shipping?.email}
                </a>
                <div className={"ml-auto"}>
                    <CopyToClipboard text={`${userData?.email}`}>
                        <p className={"uppercase tracking-widest flex-shrink-0 text-xs font-medium text-primary-default cursor-pointer"} onClick={() => {  }}>
                            COPY
                        </p>
                    </CopyToClipboard>
                </div>
            </div>
            <div className={"h-px w-full bg-gray-200"}/>
            <div className={"text-sm font-medium max-w-sm"}>
                Shipping Address
            </div>
            <div className={"flex"}>
                <div>
                    <p className={"mb-2"}>
                        {order?.shipping.name}
                    </p>
                    <p>
                        {order?.shipping.primaryLine}
                    </p>
                    <p className={"mb-1"}>
                        {order?.shipping.secondaryLineWithZipCode}
                    </p>
                    <p>
                        +91 {order?.shipping.phoneNumber}
                    </p>
                </div>
                <div className={"ml-auto"}>
                    <CopyToClipboard text={`${order?.shipping.name}, ${order?.shipping.primaryLine}, ${order?.shipping.secondaryLineWithZipCode}, +91 ${order?.shipping.phoneNumber}`}>
                        <p className={"uppercase tracking-widest flex-shrink-0 text-xs font-medium text-primary-default cursor-pointer"} onClick={() => {  }}>
                            COPY
                        </p>
                    </CopyToClipboard>
                </div>
            </div>
        </Card>
    )

}

export default CustomerDetails;