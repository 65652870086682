import React  from "react";
import FormCheckBox from "../../../../components/forms/form-checkbox";
import {Link, useHistory} from "react-router-dom";
import {UserData} from "../../../../models/user-data";
import {formatCurrency} from "../../../../utils/others";

const CustomerRows = ({customer, selected, onSelect}: { customer: UserData, selected: string[], onSelect: (id:string) => void }) => {

    const history = useHistory();

    return (
        <tr className={"border-b border-gray-200 hover:bg-gray-50 cursor-pointer"} onClick={() => { history.push("/customers/" + customer.uid); }}>
            <td className={"px-3"} onClick={(event) => { event.stopPropagation(); onSelect(customer.uid) }}>
                <FormCheckBox onChange={() => {}}  checked={selected.includes(customer.uid)}/>
            </td>
            <td className="sm:p-3 py-2 px-1">
                <Link to={"/customers/" + customer.uid} className="flex items-center hover:text-primary-default">
                    <div>
                        <span className={"font-medium"}>
                            {customer.name ?? "No Name"}
                            <div className="text-gray-400 text-xs">
                            {customer.email}
                        </div>
                        </span>
                    </div>
                </Link>
            </td>
            <td className="sm:p-3 py-2 px-1">
                <div className="flex items-center">
                    <div className="flex flex-col">
                        {customer.recent_city}
                        <div className="text-gray-400 text-xs">
                            {customer.recent_state}
                        </div>
                    </div>
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1" onClick={() => {}}>
                <div className="flex items-center">
                    <div className="flex flex-col">
                        {formatCurrency(customer.orderTotal)}
                        <div className="text-gray-400 text-xs">
                            {customer.delivered_orders ?? 0} {(customer.delivered_orders ?? 0) === 1 ? "Order" : "Orders"}
                        </div>
                    </div>
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1" onClick={() => {}}>
                <div className="flex items-center">
                    <div className="flex flex-col">
                        {(() => {
                            if (customer && customer.points <= 1000)
                                return <span>Basic</span>
                            else if (customer.points > 1000 && customer.points <= 10000)
                                return <span>Gold</span>
                            else
                                return <span>Platinum</span>
                        })()}
                        <div className="text-gray-400 text-xs">
                            {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(customer.redeemablePoints ?? 0)}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )

}

export default CustomerRows;