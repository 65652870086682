import axios from "axios";
import React, {useEffect, useState} from "react";
import {auth, BASE_API, data_index} from "../../../../utils/firebase.utils";
// @ts-ignore
import { Buy, TwoUsers, Wallet } from 'react-iconly'
import {FiArrowDown, FiArrowUp} from "react-icons/all";
import Loading from "../../../../components/on-load-viewer/loading";
import {doc, getDoc} from "firebase/firestore";

const OverviewBlocks = () => {

    const [loading, setLoading] = useState<string[]>([]);
    const [data, setData] = useState<any>({});

    useEffect(() => {
        auth.currentUser?.getIdToken().then((token) => {
            let l_data: any = {};
            getDoc(doc(data_index, "order_status")).then((doc) => {
                if (doc.exists()) {
                    l_data["sales"] = {
                        current: doc.data()?.today_sales ?? "0",
                        previous: doc.data()?.yesterday_sales ?? "0"
                    }
                    l_data["orders"] = {
                        current: doc.data()?.today_orders ?? "0",
                        previous: doc.data()?.yesterday_orders ?? "0"
                    }

                    setData(l_data);
                    setLoading((prevLoading) => { return [...prevLoading, "sales", "orders"] });
                }
            });
            axios.post(`${BASE_API}/analytics`, { template: "overview" }, { headers: { authorization: `Bearer ${token}` } }).then(async (res) => {
                if (res.status < 201) {
                    l_data["sessions"] = {
                        current: res.data.reports[0]?.rows[0]?.metricValues[0]?.value ?? 0,
                        previous: res.data.reports[1]?.rows[0]?.metricValues[0]?.value ?? 0
                    }

                    setData(l_data);
                    setLoading((prevLoading) => { return [...prevLoading, "sessions"] });
                }
            });
        });
    }, []);

    const overviewItems = [
        {
            icon: Wallet,
            label: "Today's total sales",
            value: data?.sales?.current ?? "0",
            previousValue: data?.sales?.previous ?? "0",
            key: "sales"
        },
        {
            icon: Buy,
            label: "Today's Orders",
            value: data?.orders?.current ?? "0",
            previousValue: data?.orders?.previous ?? "0",
            key: "orders"
        },
        {
            icon: TwoUsers,
            label: "Today's Visits",
            value: data?.sessions?.current ?? "0",
            previousValue: data?.sessions?.previous ?? "0",
            key: "sessions"
        }
    ]

    return (
        <div className="flex -space-x-px">
            {
                overviewItems.map((item, index) => {

                    let comparisonPercentage = "0%";
                    let state = "neutral";

                    let p = parseFloat(item.previousValue);
                    let c = parseFloat(item.value);

                    if (p === 0 && c!==0){
                        comparisonPercentage = "100%"
                        state = "increase";
                    } else if (c !== 0 || p !== 0) {
                        comparisonPercentage = Math.abs((((c-p)/p) * 100)).toFixed(0) + "%";
                        state = (c > p || c===p) ? "increase" : "decrease";
                    }

                    let color;

                    switch (state) {
                        case "increase":
                            color = "text-green-600";
                            break;
                        case "decrease":
                            color = "text-yellow-600";
                            break;
                        default:
                           color = "text-gray-400";
                           break;
                    }

                    let icon;

                    switch (state) {
                        case "increase":
                            icon = <FiArrowUp size={12} className={`${color} mr-1`}/>;
                            break;
                        case "decrease":
                            icon = <FiArrowDown size={12} className={`${color} mr-1`}/>;
                            break;
                        default:
                            icon = null;
                           break;
                    }

                    let dataLoading = !loading.includes(item.key);

                    return <div key={item.key} className={`appearance-none bg-gray-50 hover:bg-white transition duration-200 rounded-none relative block border border-gray-200 h-28 flex flex-grow flex-col justify-center items-center ${index === 0 ? "rounded-l-md" : (index===2 ? "rounded-r-md" : "")}`}>
                        <p className={`text-2xl font-medium transition duration-300 ${dataLoading ? "opacity-0" : "opacity-100"}`}>
                            {index === 0 ?
                                `₹${Intl.NumberFormat('en', { notation: "compact", maximumFractionDigits: 2 }).format(parseFloat(item.value))}`
                                :
                                item.value
                            }
                        </p>
                        <p className={"text-sm font opacity-60"}>
                            {item.label}
                        </p>
                        <div className={`flex items-center transition duration-300 mt-0.5 ${!dataLoading ? "opacity-100" : "opacity-0"}`}>
                            {icon &&
                                icon
                            }
                            <p className={`${color} text-sm`}>{comparisonPercentage}</p>
                        </div>
                        <div className={`absolute right-2 top-2 flex items-center transition duration-300 ${dataLoading ? "opacity-100" : "opacity-0"}`}>
                            <Loading size={3}/>
                        </div>

                    </div>
                })
            }
        </div>
    )

}

export default OverviewBlocks;