import Card from "../../../../components/cards/card";
import React from "react";
import {Order} from "../../../../models/order";
import {FaRupeeSign, HiCash} from "react-icons/all";
// @ts-ignore
import { TickSquare } from 'react-iconly'
import {formatCurrency, getMethodIcon} from "../../../../utils/others";
const OrderPayments = ({order}: {order?: Order}) => {

    return (
        <Card className={"overflow-visible space-y-4"}>
            <div className={"text-xl font-semibold max-w-sm flex items-center"}>
                {order?.paymentMethod === "cod" ?  <HiCash size={24} className={"mr-2 text-green-600"}/> : <TickSquare set={"bold"} size={20} className={"mr-2 text-green-600"}/>}
                {order?.paymentMethod === "cod" ? "Cash on Delivery" : "Paid"}
            </div>
            {order?.items?.length && order?.items?.length > 0 &&
            <div className={"pt-4 py-0 space-y-2"}>
                <div className={"flex justify-between"}>
                    <p className={"font-normal font-sans text-sm opacity-90"}>Subtotal — { order.items.length } item(s)</p>
                    <p className={"font-medium font-sans text-sm"}>{formatCurrency(order.subtotal)}</p>
                </div>
                {order?.retailDiscount > 0 &&
                    <div className={"flex justify-between"}>
                        <p className={"font-normal font-sans text-sm opacity-90"}>Retail Discount</p>
                        <p className={"font-medium font-sans text-sm text-green-700"}>- {formatCurrency(order.retailDiscount)}</p>
                    </div>
                }
                {order?.promotionalDiscount > 0 &&
                    <div className={"flex justify-between"}>
                        <p className={"font-normal font-sans text-sm opacity-90"}>Coupon Discount ({order.promoCode})</p>
                        <p className={"font-medium font-sans text-sm text-green-700"}>- {formatCurrency(order.promotionalDiscount)}</p>
                    </div>
                }
                {order?.memberDiscount > 0 &&
                    <div className={"flex justify-between"}>
                        <p className={"font-normal font-sans text-sm opacity-90"}>Membership Discount</p>
                        <p className={"font-medium font-sans text-sm text-green-700"}>- {formatCurrency(order.memberDiscount)}</p>
                    </div>
                }
                <div className={"flex justify-between py-3"} style={{ borderBottom: "1px solid rgba(100,50,50,0.1)" }}>
                    <p className={"font-normal font-sans text-sm opacity-90"}>Shipping</p>
                    <p className={"font-medium font-sans text-sm"}>{ order.shippingCost === 0 ? "Free" :  `+ ₹ 95`}</p>
                </div>
                <div className={"flex justify-between items-start pt-2"}>
                    <div>
                        <p className={"font-medium font-sans "}>Total</p>
                        <p className={"font-normal font-sans  opacity-90"}>
                            Including {formatCurrency(order.taxes)} in taxes
                        </p>
                    </div>
                    <p className={"flex items-center font-bold font-sans text-3xl"}>
                        <FaRupeeSign size={16}/>{new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0}).format(order.total)}
                    </p>
                </div>
            </div>
            }
            <div style={{ borderBottom: "1px solid rgba(100,50,50,0.1)" }}/>
            <div>
                {order?.paymentMethod === "card" &&
                    <div className={"flex items-center"}>
                        <span className={"mr-2"}>Paid with</span>
                        <div className={"h-full items-center flex bg-background-dark px-1 pr-2 py-1 rounded-md"}>
                            <img className={"rounded-md overflow-hidden mr-3"} alt={"Credit Card"} src={order?.card?.brandIcon} height={20}
                                 style={{ height: "22px" }}
                            />
                            <p className={"font-sans font-medium text-sm"}>
                                {"●●●● " +(order?.card?.last4 ?? "")}
                            </p>
                        </div>
                    </div>

                }
                {order?.paymentMethod === "wallet" &&
                    <div className={"flex items-center"}>
                        <span className={"mr-2"}>Paid with</span>
                        <div className={"h-full items-center flex bg-background-dark px-1 pr-2 py-1 rounded-md"}>
                            <img className={"rounded-md overflow-hidden mr-2 ml-1"} alt={"Wallet"} src={getMethodIcon(order.paymentDetails)} height={20}
                                 style={{ height: "16px" }}
                            />
                            <p className={"font-sans font-medium text-sm"}>
                                {order.paymentDetails}
                            </p>
                        </div>
                    </div>

                }
                {order?.paymentMethod === "cod" &&
                    <div className={"flex items-center"}>
                        <span className={"mr-2"}>Customer will </span>
                        <div className={"h-full items-center flex bg-background-dark px-1 pr-2 py-1 rounded-md"}>
                            <img className={"rounded-md overflow-hidden mr-2 ml-1"} alt={"COD"} src={getMethodIcon(order.paymentMethod)} height={20}
                                 style={{ height: "16px" }}
                            />
                            <p className={"font-sans font-medium text-sm"}>
                                Pay on Delivery
                            </p>
                        </div>
                    </div>

                }
                {order?.paymentMethod === "prepaid" &&
                    <div className={"flex items-center"}>
                        <span className={"mr-2"}>Admin paid with</span>
                        <div className={"h-full items-center flex bg-background-dark px-1 pr-2 py-1 rounded-md"}>
                            <img className={"rounded-md overflow-hidden mr-2 ml-1"} alt={"Admin Price"} src={getMethodIcon(order.paymentMethod)} height={20}
                                 style={{ height: "16px" }}
                            />
                            <p className={"font-sans font-medium text-sm"}>
                                Special Pricing
                            </p>
                        </div>
                    </div>

                }
            </div>

        </Card>
    )

}

export default OrderPayments;