import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { createStore } from 'redux';
import allReducers from "./redux/index.reducers";

const store = createStore(allReducers);

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
          <Switch>
              <Route path="/" component={App} />
          </Switch>
      </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
