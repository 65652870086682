import React, {useEffect, useRef, useState} from "react";
import Heading from "../components/heading";
import FormCheckBox from "../../../components/forms/form-checkbox";
import {products} from "../../../utils/firebase.utils";
import ProductRow from "./components/product-row";
import {onSnapshot, orderBy, query} from "firebase/firestore";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import {ProductItem} from "../../../models/product-item";

const Products = () => {

    const ref = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(true);
    const [productsData, setProductsData] = useState<ProductItem[]>([]);
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {

        let ref = query(
            products,
            orderBy('title')
        );

        let observer = onSnapshot(ref, (snapshot) => {
            if (snapshot.empty) {
                setProductsData([])
            } else {
                let t: ProductItem[] = [];
                snapshot.docs.forEach((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    t.push(new ProductItem(data));
                })
                setProductsData(t);
            }
            setLoading(false);
        })

        return () => observer();
    }, [])

    const selectProduct = (id: string) => {
        let t: string[] = [...selected];

        if (t.includes(id)) {
            t.splice(t.indexOf(id), 1);
        } else {
            t.push(id);
        }
        setSelected(t);
    }

    return (
        <>
            <div className="sm:pt-7 pt-4 flex flex-col w-full bg-white sticky top-0 z-10" ref={ref} style={{ boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                <Heading title={`Products`}/>
                <div className="flex items-center space-x-3 sm:mt-7 mt-4">
                    <div className="border-b-2 border-indigo-500 text-primary-default pb-1.5 font-medium">All</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5">Active</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5 sm:block hidden">Disabled</div>
                    <div className="px-3 border-b-2 border-transparent text-gray-600 pb-1.5 sm:block hidden">Drafts</div>
                </div>
            </div>
            <div className="space-y-6">
                <table className="w-full text-left">
                    <thead className={`h-12 bg-white px-3 py-3 text-gray-400 sticky relative z-10`} style={{ top: `${ref.current ? `${ref.current.clientHeight}px` : ""}`, boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                        <tr>
                            <th className="px-3">
                                <FormCheckBox onChange={() => { if (selected.length === productsData.length) setSelected([]); else setSelected(productsData.map(o => o.id)); }} checked={productsData.length > 0 && selected.length === productsData.length}/>
                            </th>
                            <th className="font-normal sm:p-3 py-2 px-1">Image</th>
                            <th className="font-normal sm:p-3 py-2 px-1">Product</th>
                            <th className="font-normal sm:p-3 py-2 px-1 hidden md:table-cell">Collection</th>
                            <th className="font-normal sm:p-3 py-2 px-1 sm:text-gray-400 text-white">Status</th>
                        </tr>
                    </thead>
                    <tbody className="text-raisinBlack relative">
                        <OnLoadViewer loading={loading} className={"absolute"}>
                            {
                                productsData.map((product) => {
                                    return <ProductRow key={product.id} onSelect={selectProduct} selected={selected} product={product}/>
                                })
                            }
                        </OnLoadViewer>
                    </tbody>
                </table>
            </div>
        </>
)
}

export default Products;