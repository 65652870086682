import FreeCharge from "../assets/wallets_logos/freecharge.png";
import MobiKwik from "../assets/wallets_logos/mobikwik.png";
import JioMoney from "../assets/wallets_logos/jiomoney.png";
import OlaMoney from "../assets/wallets_logos/ola_money.png";
import PhonePe from "../assets/wallets_logos/phonepe.png";
import AmazonPay from "../assets/wallets_logos/amazonpay.png";
import PayTM from "../assets/wallets_logos/paytm.png";
import GooglePay from "../assets/wallets_logos/googlepay.png";
import PayZapp from "../assets/wallets_logos/payzapp.png";
import AirtelMoney from "../assets/wallets_logos/airtelmoney.png";
import Cash from "../assets/wallets_logos/money.png";
import UPI from "../assets/wallets_logos/UPI.png";
import Wallets from "../assets/wallets_logos/core_wallet.png";

export const formatCurrency = (val: number) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0, style: 'currency', currency: "INR"}).format(val)
}

export const getMethodIcon = (code: string) => {
    switch (code) {
        case "freecharge":
        case "FreeCharge":
            return FreeCharge;
        case "mobikwik":
        case "MobiKwik":
            return MobiKwik;
        case "jiomoney":
        case "JioMoney":
            return JioMoney;
        case "payzapp":
        case "PayZapp":
            return PayZapp;
        case "airtelmoney":
        case "Airtel Money":
            return AirtelMoney;
        case "olamoney":
        case "Ola Money":
            return OlaMoney;
        case "phonepe":
        case "PhonePe":
            return PhonePe;
        case "amazonpay":
            return AmazonPay;
        case "paytm":
        case "Paytm":
            return PayTM;
        case "google_pay":
            return GooglePay;
        case "upi":
            return UPI;
        case "cash":
        case "cod":
            return Cash;
        default:
            return Wallets;
    }
}
export const getMethodText = (code: string) => {
    return supportedWallets.find((w) => w.code === code)?.title ?? "Wallets";
}

export const supportedWallets = [
    {
        title: "Paytm",
        method: "wallet",
        code: "paytm"
    },
    {
        title: "PhonePe",
        method: "wallet",
        code: "phonepe"
    },
    {
        title: "Ola Money",
        method: "wallet",
        code: "olamoney"
    },
    {
        title: "FreeCharge",
        method: "wallet",
        code: "freecharge"
    },
    {
        title: "PayZapp",
        method: "wallet",
        code: "payzapp"
    },
    {
        title: "MobiKwik",
        method: "wallet",
        code: "mobikwik"
    },
    {
        title: "JioMoney",
        method: "wallet",
        code: "jiomoney"
    },

    {
        title: "Airtel Money",
        method: "wallet",
        code: "airtelmoney"
    }
];