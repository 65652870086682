import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Heading from "../components/heading";
import OverviewBlocks from "./sections/overview-blocks";
import OrdersToFulFill from "./sections/overview-to-fulfill";

const Overview = () => {

    const {userData} = useSelector((state: RootState) => state.userData);

    return (
        <>
            <div className="sm:py-7 py-4 flex flex-col w-full border-b border-gray-200 bg-white sticky top-0">
                <Heading title={`Hey ${userData?.firstName}`} subtitle={"Here's what's happening with your store today."}/>
            </div>
            <div className="sm:py-7 py-4 space-y-6 max-w-prose">
                <OverviewBlocks/>
                <OrdersToFulFill/>
            </div>
        </>
    )
}

export default Overview;