import React from "react";
import {Link, Route, Switch, useLocation} from "react-router-dom";
// @ts-ignore
import { Category, Buy, Discount, Chart, TwoUsers, Document } from 'react-iconly'
import Overview from "./overview/overview";
import Logo from "../../assets/logo.webp";
import Orders from "./orders/orders";
import Products from "./products/products";
import ProductDetails from "./products-details/product-details";
import OrderDetails from "./order-details/order-details";
import Customers from "./customers/customers";
import CustomerDetails from "./customer-details/customer-details";

const DashboardWrapper = () => {

    const location = useLocation();

    const menuItems = [
        {
            icon: Category,
            label: "Overview",
            to: "/"
        },
        {
            icon: Buy,
            label: "Orders",
            to: "/orders"
        },
        {
            icon: Document,
            label: "Products",
            to: "/products"
        },
        {
            icon: TwoUsers,
            label: "Customers",
            to: "/customers"
        },
        {
            icon: Discount,
            label: "Discounts",
            to: "/discounts"
        },
        {
            icon: Chart,
            label: "Analytics",
            to: "/analytics"
        }
    ]

    return (
        <div className="text-gray-600 flex text-sm">

            {/*Drawer*/}
            <div className="fixed h-screen bg-gray-50 py-2 flex-shrink-0 border-r w-1/6 border-gray-200 flex-col hidden sm:flex bg-background-default">
                <div className={"h-16 px-8 py-4 flex w-full items-center justify-between"}>
                    <img
                        className="h-5"
                        src={Logo}
                        alt="Attic Cr."
                    />
                </div>
                <div className="flex flex-col text-gray-800 mt-4">
                    {
                        menuItems.map((item) => {

                            const active = (location.pathname.startsWith(item.to) && item.to !== "/") || (location.pathname === item.to);

                            return (
                                <Link key={item.to} to={item.to}>
                                    <button className={`group px-8 h-10 flex w-full items-center justify-start text-base ${active ? "font-medium" : ""} ${active ? "text-primary-default" : ""} focus:outline-none`}>
                                        {item.icon &&
                                            React.createElement(item.icon, { set: 'bulk', size: 18, style: {flexShrink: 0}, className: ` opacity-80 group-hover:opacity-100 transition duration-200 ${active ? "text-primary-default opacity-100" : ""}` })
                                        }
                                        <span className={"ml-4"}>
                                            {item.label}
                                        </span>
                                    </button>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>

            <div className="bg-white flex-grow flex flex-col items-center ml-0 sm:ml-16p">

                {/*Navbar*/}
                <div className="h-16 flex w-full border-b z-50 bg-white border-gray-200 px-10 flex-shrink-0 sticky top-0">
                    <div className="flex h-full text-gray-600">
                        <div className="cursor-pointer h-full border-b-2 border-transparent inline-flex items-center mr-8">Search</div>
                    </div>
                </div>

                {/*Content*/}
                <div className="flex-grow flex w-full">
                    <div className="flex-grow bg-white">
                        <div className={"max-w-screen-xl px-4 sm:px-6 md:px-10 mx-auto"}>
                            <Switch>
                                <Route exact path={'/'} component={Overview} />

                                <Route exact path={'/orders'} component={Orders} />
                                <Route exact path={'/orders/:id'} component={OrderDetails} />

                                <Route exact path={'/customers'} component={Customers} />
                                <Route exact path={'/customers/:id'} component={CustomerDetails} />

                                <Route exact path={'/products'} component={Products} />
                                <Route exact path={'/products/:id'} component={ProductDetails} />
                                {/*<OpenRoute>*/}
                                {/*    <div>404</div>*/}
                                {/*</OpenRoute>*/}
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DashboardWrapper;