import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {Role, SET_USER, UPDATE_USER, UserActionTypes} from "./redux/types/user-data-types";
import {RootState} from "./redux/index.reducers";
import {auth, sites, users} from "./utils/firebase.utils";
import {UserData} from "./models/user-data";
import AppRoutes from "./components/app-routes/app-routes";
import {SET_SITE_SETTINGS, SiteSettingsActionTypes} from "./redux/types/site-types";
import {doc, getDoc, limit, onSnapshot, query, where } from 'firebase/firestore';
import {SiteSettings} from "./models/site-settings";
import { onAuthStateChanged } from 'firebase/auth';
import Favicon from "react-favicon";

const App = () => {

    const dispatch = useDispatch<Dispatch<UserActionTypes | SiteSettingsActionTypes>>();
    const userData = useSelector((state: RootState) => state.userData);
    const site = useSelector((state: RootState) => state.site);

    useEffect(() => {
        // let domain = window.location.host;

        let ref = query(sites, where('domain', '==', "admin.groomd.co.in"), limit(1));
        onSnapshot(ref, (snapshot) => {
            if (!snapshot.empty) {
                let data = snapshot.docs[0].data();
                data.id = snapshot.docs[0].id;

                let confSettings = new SiteSettings(data);

                dispatch({type: SET_SITE_SETTINGS, payload: { settings: confSettings }});
            }
        });

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                auth.currentUser?.getIdTokenResult()
                    .then(async (result) => {

                        let roles: Role[] = [];
                        if (result.claims["app_roles"]) {
                            roles = result.claims["app_roles"] as Role[];
                        }

                        dispatch({type: SET_USER, payload: {user: user, userData: new UserData({uid: user.uid, email: user.email}), role: roles}});
                    })
                    .then(async () => {
                        let document = await getDoc(doc(users, user.uid));
                        let userData;
                        if (document.exists()) {
                            let data = document.data();
                            data.uid = user.uid;
                            userData = new UserData(data);
                        } else {
                            userData = new UserData({
                                uid: user.uid, email: user.email
                            });
                        }
                        dispatch({type: UPDATE_USER, payload: {userData: userData}});
                    })
            } else {
                dispatch({type: SET_USER, payload: {user: null, userData: null, role: []}});
            }
        });


    }, [dispatch])

    return (
        <>
            <Favicon url={site?.icon ?? ""}/>
            {/*<NavBar/>*/}
            {(!userData.loading) &&
                <AppRoutes/>
            }
        </>
    );
}

export default App;