import React from 'react'
import {Redirect, Route, RouteProps, Switch} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import SignIn from "../../main-pages/sign-in/sign-in";
import * as queryString from "querystring";
import ForgotPassword from "../../main-pages/forgot-password/forgot-password";
import DashboardWrapper from "../../main-pages/dashboard/dashboard-wrapper";
import {Role} from "../../redux/types/user-data-types";

const NoAuthRoute = ({component, redirect = true, ...props}: RouteProps & { redirect?: boolean }) => {

    const { isAuthenticated } = useSelector((state: RootState) => state.userData);

    let redirectUrl = queryString.parse(props.location?.search ?? "")?.redirect ?? null;

    return !isAuthenticated || !redirect ? (
        <Route {...props} component={component} />
    ) : (
        <Redirect to={redirectUrl!=null ? redirectUrl.toString() : "/"}/>
    );
};

const PrivateRoute = ({ roles = [], component, ...props }: RouteProps & {roles?: Role[]}) => {

    const {isAuthenticated, role} = useSelector((state: RootState) => state.userData);

    let allowed:boolean = false;
    if (roles?.length > 0) {
        roles?.forEach((r) => {
            if (role?.includes(r))
                allowed = true;
        })
    } else {
        //TODO Uncomment
        // if ((role?.length ?? 0) > 0)
            allowed = true;
    }

    return (isAuthenticated) ?
        (
            allowed ?
                <Route {...props} component={component} />
                :
                <Redirect to={`/not-authorised`}/>
        )
        :
        <Redirect to={`/sign-in?redirect=${props.location?.pathname}${props.location?.search}`}/>
};

const AppRoutes = ()  => {

    return (
        <main>
            <Switch>

                {/*Authentication Routes - Login & Forgot Password*/}
                <NoAuthRoute exact path='/sign-in' component={SignIn} />
                <NoAuthRoute exact path='/forgot-password' component={ForgotPassword} />

                {/*All Dashboard Content*/}
                <PrivateRoute path={'/*'} component={DashboardWrapper} />

                {/*<OpenRoute>*/}
                {/*    <div>404</div>*/}
                {/*</OpenRoute>*/}
            </Switch>
        </main>
    )
};

export default AppRoutes;

